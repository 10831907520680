<template>
  <div v-if="customSelectionText" class="flex items-center justify-between">
    <span class="body-1-bold">{{ customSelectionText.title }}</span>
    <span class="text-static-default-low body-2">
      {{ customSelectionText.price }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { StepIndexes, type StepStatus } from '../../NoGrade.constants'

import translations from './Selection.translations'

const i18n = useI18n()

const props = defineProps<{
  stepStatuses: Record<number, StepStatus>
  priceWithoutPremium: Price | null | undefined
  hasOneCombination: boolean
}>()

const hasCustomSelection = computed(() => {
  return (
    props.stepStatuses[StepIndexes.SCREEN] !== 'unknown' ||
    props.stepStatuses[StepIndexes.SHELL] !== 'unknown' ||
    props.stepStatuses[StepIndexes.BATTERY] !== 'unknown'
  )
})

const hasAccepedPremium = computed(() => {
  return props.stepStatuses[StepIndexes.PREMIUM] === 'accepted'
})

const customSelectionText = computed(() => {
  // unknown everywhere
  // price is the one we have in selected offer
  if (
    !hasCustomSelection.value &&
    props.priceWithoutPremium &&
    !props.hasOneCombination
  ) {
    return {
      title: i18n(translations.customSelectionTitle),
      price: i18n(translations.customSelectionPrice, {
        price: i18n.price(props.priceWithoutPremium),
      }),
    }
  }

  // Chosen (accepted or declined) anything + premium is selected
  if (
    hasCustomSelection.value &&
    hasAccepedPremium.value &&
    props.priceWithoutPremium
  ) {
    return {
      title: i18n(translations.previousSelectionTitle),
      price: i18n(translations.previousSelectionPrice, {
        price: i18n.price(props.priceWithoutPremium),
      }),
    }
  }

  // Chosen (accepted or declined) anything + premium is not selected
  if (
    props.priceWithoutPremium &&
    ((hasCustomSelection.value && !hasAccepedPremium.value) ||
      props.hasOneCombination)
  ) {
    return {
      title: i18n(translations.yourSelectionTitle),
      price: i18n(translations.yourSelectionPrice, {
        price: i18n.price(props.priceWithoutPremium),
      }),
    }
  }

  return null
})
</script>
