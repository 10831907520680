<template>
  <RevContainer v-if="shouldDisplay" class="bg-surface-default-low pb-80 pt-24">
    <StepLayout>
      <template #left>
        <LeftColumn
          :active-grade="customSelection.grade"
          :active-step
          :brand
          :is-premium-step-alone
          :is-step-battery-available
          :is-step-body-available
          :is-step-premium-available
          :is-step-screen-available
          :offer-grade-without-premium="customSelectionWithoutPremium.grade"
          :offer-type-without-premium="customSelectionWithoutPremium.offerType"
          :pickers
          :unavailable-body-img="
            unavailableBodyProps ? unavailableBodyProps.image : ''
          "
          :unavailable-screen-img="
            unavailableScreenProps ? unavailableScreenProps.image : ''
          "
        />
      </template>
      <template #right>
        <div class="flex flex-col items-center">
          <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
          <div
            class="flex w-full flex-col items-center"
            :class="{
              'border-static-default-mid rounded-lg w-full cursor-pointer border p-12':
                shouldDisplaySelection,
              'bg-surface-brand-hi border-static-brand-mid':
                hasCustomSelection && !hasPremiumSelection,
            }"
            tabindex="0"
            @click="handleSelectionClick"
            @keydown.enter="handleSelectionClick"
          >
            <StepScreenBody
              v-if="isStepScreenAvailable"
              :class="getClassesForIndex(StepIndexes.SCREEN)"
              :images="
                [
                  getGradeImage(pickers, stepScreenUpdate, 'screen'),
                  getGradeImage(pickers, '12', 'screen'),
                ].filter(Boolean) as Array<string>
              "
              :is-loading
              :is-opened="activeStep === StepIndexes.SCREEN"
              :prices="
                [
                  getGradePrice(pickers, stepScreenUpdate),
                  getGradePrice(pickers, '12'),
                ].filter(Boolean) as Array<Price>
              "
              :status="getStepStatusWithPremium(StepIndexes.SCREEN)"
              :style="{ width: getWidthForIndex(StepIndexes.SCREEN) }"
              :tracking="{
                block_name: 'no_grade_screen',
                product_model: model,
                product_category: category,
              }"
              type="screen"
              @accept="
                accept({ l: parseInt(stepScreenUpdate, 10), offerType: 0 })
              "
              @click="goToStep(StepIndexes.SCREEN)"
              @decline="decline({ l: 12, offerType: 0 })"
            />
            <StepUnavailable
              v-else-if="unavailableScreenProps"
              v-bind="unavailableScreenProps"
              :class="getClassesForIndex(StepIndexes.SCREEN)"
              :is-loading
              :is-opened="activeStep === StepIndexes.SCREEN"
              :style="{ width: getWidthForIndex(StepIndexes.SCREEN) }"
              :tracking="{
                block_name: 'no_grade_oos_screen',
                product_model: model,
                product_category: category,
              }"
              @click="goToStep(StepIndexes.SCREEN)"
              @continue="goToNextStep"
            />

            <StepScreenBody
              v-if="isStepBodyAvailable"
              :class="getClassesForIndex(StepIndexes.SHELL)"
              :images="
                [
                  getGradeImage(pickers, '10', 'body'),
                  getGradeImage(pickers, customSelection.grade, 'body'),
                ].filter(Boolean) as Array<string>
              "
              :is-loading
              :is-opened="activeStep === StepIndexes.SHELL"
              :prices="
                [
                  getGradePrice(pickers, '10'),
                  getGradePrice(pickers, customSelection.grade),
                ].filter(Boolean) as Array<Price>
              "
              :status="getStepStatusWithPremium(StepIndexes.SHELL)"
              :style="{ width: getWidthForIndex(StepIndexes.SHELL) }"
              :tracking="{
                block_name: 'no_grade_body',
                product_model: model,
                product_category: category,
              }"
              type="body"
              @accept="accept({ l: 10, offerType: 0 })"
              @click="goToStep(StepIndexes.SHELL)"
              @decline="
                decline({
                  l: parseInt(customSelection.grade, 10),
                  offerType: 0,
                })
              "
            />
            <StepUnavailable
              v-else-if="unavailableBodyProps"
              v-bind="unavailableBodyProps"
              :class="getClassesForIndex(StepIndexes.SHELL)"
              :is-loading
              :is-opened="activeStep === StepIndexes.SHELL"
              :style="{ width: getWidthForIndex(StepIndexes.SHELL) }"
              :tracking="{
                block_name: 'no_grade_oos_body',
                product_model: model,
                product_category: category,
              }"
              @click="goToStep(StepIndexes.SHELL)"
              @continue="goToNextStep"
            />

            <StepBattery
              v-if="shouldDisplayBatteryStep && isStepBatteryAvailable"
              :class="getClassesForIndex(StepIndexes.BATTERY)"
              :is-loading
              :is-opened="activeStep === StepIndexes.BATTERY"
              :prices="
                [
                  getGradePrice(pickers, customSelection.grade, 'new_battery'),
                  getGradePrice(pickers, customSelection.grade),
                ].filter(Boolean) as Array<Price>
              "
              :status="getStepStatusWithPremium(StepIndexes.BATTERY)"
              :style="{ width: getWidthForIndex(StepIndexes.BATTERY) }"
              :tracking="{
                block_name: 'no_grade_screen',
                product_model: model,
                product_category: category,
              }"
              @accept="accept({ offerType: 7 })"
              @click="goToStep(StepIndexes.BATTERY)"
              @decline="decline({ offerType: 0 })"
            />
            <StepUnavailable
              v-else-if="shouldDisplayBatteryStep"
              :class="getClassesForIndex(StepIndexes.BATTERY)"
              :description="i18n(unavailableTranslations.batteryDescription)"
              :heading="i18n(unavailableTranslations.batteryHeader)"
              image="/img/product/no-grade/battery-disabled.png"
              :is-loading
              :is-opened="activeStep === StepIndexes.BATTERY"
              :status="getStepStatusWithPremium(StepIndexes.BATTERY)"
              :style="{ width: getWidthForIndex(StepIndexes.BATTERY) }"
              :title="i18n(unavailableTranslations.batteryTitle)"
              :tracking="{
                block_name: 'no_grade_oos_battery',
                product_model: model,
                product_category: category,
              }"
              @click="goToStep(StepIndexes.BATTERY)"
              @continue="goToNextStep"
            />

            <Selection
              v-if="shouldDisplaySelection"
              class="mt-8 w-[95%]"
              :has-one-combination
              :price-without-premium="
                getGradePrice(
                  pickers,
                  customSelectionWithoutPremium.grade,
                  customSelectionWithoutPremium.offerType,
                )
              "
              :step-statuses
            />
          </div>

          <StepPremiumDivider v-if="shouldDisplayPremiumDivider" />

          <StepPremium
            v-if="isStepPremiumAvailable"
            :brand
            :class="getClassesForIndex(StepIndexes.PREMIUM)"
            :is-loading
            :is-opened="activeStep === StepIndexes.PREMIUM"
            :is-premium-step-alone
            :offer-grade-without-premium="customSelectionWithoutPremium.grade"
            :offer-type-without-premium="
              customSelectionWithoutPremium.offerType
            "
            :prices="
              [
                getGradePrice(pickers, '9'),
                getGradePrice(
                  pickers,
                  customSelection.grade,
                  customSelection.offerType,
                ),
              ].filter(Boolean) as Array<Price>
            "
            :status="stepStatuses[StepIndexes.PREMIUM]"
            :style="{ width: getWidthForIndex(StepIndexes.PREMIUM) }"
            :tracking="premiumTracking"
            @accept="accept({ offerType: 0, l: 9 })"
            @click="goToStep(StepIndexes.PREMIUM)"
            @decline="
              decline({
                l: parseInt(customSelection.grade, 10),
                offerType: customSelection.offerType === 'default' ? 0 : 7,
              })
            "
          />
        </div>
      </template>
    </StepLayout>

    <NewBatteryModal
      v-if="isStepBatteryAvailable || isBatteryStepAbsent"
      :name="MODAL_NAMES.NEW_BATTERY_NO_GRADE"
      :variant="newBatteryModalVariant"
    />
  </RevContainer>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, provide } from 'vue'

import { MarketCountryCode, type Price } from '@backmarket/http-api'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { parse } from '@backmarket/utils/query-string/parse'
import { stringify } from '@backmarket/utils/query-string/stringify'
import { tw } from '@backmarket/utils/string/tw'
import { RevContainer } from '@ds/components/Container'

import type { BatteryModalVariants } from '~/scopes/product/components/CustomizationFunnel/components/NewBatteryModal/NewBatteryModal.types'
import NewBatteryModal from '~/scopes/product/components/CustomizationFunnel/components/NewBatteryModal/NewBatteryModal.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import { useNoGradeSteps } from '../../composables/useNoGradeSteps'
import { useUrlParams } from '../../composables/useUrlParams'
import { PRODUCT } from '../../route-names'
import StepLayout from '../CustomizationFunnel/components/StepLayout/StepLayout.vue'

import {
  BG_WITH_OPACITY,
  NO_GRADE_BRAND_INJECTION_KEY,
  StepIndexes,
} from './NoGrade.constants'
import {
  getGradeImage,
  getGradePrice,
  getIsStepBatteryAvailable,
  getIsStepBodyAvailable,
  getIsStepScreenAvailable,
  getStepScreenUpdate,
  stepStatusesToGradeOfferType,
} from './NoGrade.utils'
import LeftColumn from './components/LeftColumn/LeftColumn.vue'
import Selection from './components/Selection/Selection.vue'
import StepBattery from './components/StepBattery/StepBattery.vue'
import StepPremium from './components/StepPremium/StepPremium.vue'
import StepPremiumDivider from './components/StepPremiumDivider/StepPremiumDivider.vue'
import StepScreenBody from './components/StepScreenBody/StepScreenBody.vue'
import unavailableTranslations from './components/StepUnavailable/StepUnavailable.translations'
import StepUnavailable from './components/StepUnavailable/StepUnavailable.vue'

const props = withDefaults(
  defineProps<{
    category: string
    pickers: GetPickersResponse
    brand: string
    model: string
    isLoading: boolean
  }>(),
  {
    isLoading: false,
  },
)

const experiments = useExperiments()

const { activeStep, stepStatuses, setActiveStep, setStatus } = useNoGradeSteps(
  props.model,
)

const router = useRouter()
const route = useRoute()
const i18n = useI18n()
const { countryCode } = useMarketplace().market
const { withNoGrade } = useUrlParams()

const hasOneCombination = computed(() => {
  return Object.keys(props.pickers.gradeOffers).length === 1
})

const isPremiumStepAlone = computed(() => {
  return (
    hasOneCombination.value && Object.keys(props.pickers.gradeOffers)[0] === '9'
  )
})

const shouldDisplay = computed(() => {
  return experiments['experiment.ppNoGrade'] === 'withNoGrade' || withNoGrade
})
const shouldDisplaySelection = computed(() => {
  return activeStep.value >= StepIndexes.PREMIUM && !isPremiumStepAlone.value
})
const shouldDisplayBatteryStep = computed(() => {
  return (
    ![MarketCountryCode.US, MarketCountryCode.AU].includes(countryCode) &&
    !isPremiumStepAlone.value
  )
})
/**
 * is US or AU
 */
const isBatteryStepAbsent = computed(() => {
  return !shouldDisplayBatteryStep.value
})

const newBatteryModalVariant = computed<BatteryModalVariants>(() => {
  if (activeStep.value === StepIndexes.PREMIUM) {
    const isBatteryAccepted =
      stepStatuses.value[StepIndexes.BATTERY] === 'accepted'

    if (isBatteryAccepted) {
      return 'new_vs_premium'
    }

    return 'standard_vs_premium'
  }

  return 'standard_vs_new'
})

const isStepScreenAvailable = computed(() => {
  return getIsStepScreenAvailable(props.pickers)
})
const isStepBodyAvailable = computed(() => {
  return getIsStepBodyAvailable(props.pickers)
})
const isStepPremiumAvailable = computed(() => {
  return !!getGradePrice(props.pickers, '9')
})

const shouldDisplayPremiumDivider = computed(() => {
  return (
    activeStep.value >= StepIndexes.PREMIUM &&
    isStepPremiumAvailable.value &&
    !isPremiumStepAlone.value
  )
})

const stepScreenUpdate = computed(() => {
  return getStepScreenUpdate(props.pickers)
})

const customSelectionWithoutPremium = computed(() => {
  const selectedOffer = stepStatusesToGradeOfferType(
    {
      ...stepStatuses.value,
      [StepIndexes.PREMIUM]: 'unknown',
    },
    activeStep.value,
    stepScreenUpdate.value,
    props.pickers,
  )
  const activeGradeOffer = props.pickers.gradeOffers[selectedOffer.grade].find(
    ({ offerType }) => {
      const expectedOfferType = selectedOffer.offerType === 'default' ? 0 : 7

      return offerType === expectedOfferType
    },
  )

  return {
    ...selectedOffer,
    productId: activeGradeOffer?.productId,
    slugV2: activeGradeOffer?.slug,
  }
})

const customSelection = computed(() => {
  const selectedOffer = stepStatusesToGradeOfferType(
    stepStatuses.value,
    activeStep.value,
    stepScreenUpdate.value,
    props.pickers,
  )
  const activeGradeOffer = props.pickers.gradeOffers[selectedOffer.grade].find(
    ({ offerType }) => {
      const expectedOfferType = selectedOffer.offerType === 'default' ? 0 : 7

      return offerType === expectedOfferType
    },
  )

  return {
    ...selectedOffer,
    productId: activeGradeOffer?.productId,
    slugV2: activeGradeOffer?.slug,
  }
})

const isStepBatteryAvailable = computed(() => {
  return !!getIsStepBatteryAvailable(props.pickers, customSelection.value)
})

const hasPremiumSelection = computed(() => {
  return (
    shouldDisplayPremiumDivider.value &&
    stepStatuses.value[StepIndexes.PREMIUM] === 'accepted'
  )
})

// When premium is selected we make the previous steps appear as unknown
function getStepStatusWithPremium(index: number) {
  return !hasPremiumSelection.value ? stepStatuses.value[index] : 'unknown'
}

const unavailableScreenProps = computed(() => {
  if (isPremiumStepAlone.value) {
    return false
  }

  // If fair is not available, the screen is always flawless
  if (!getGradePrice(props.pickers, '12')) {
    return {
      heading: i18n(unavailableTranslations.screenFlawlessHeader),
      title: i18n(unavailableTranslations.screenFlawlessHeader),
      image: getGradeImage(props.pickers, '10', 'screen'),
      status: getStepStatusWithPremium(StepIndexes.SCREEN),
    }
  }

  // If excellent & good grades are not available, the screen is always with scratches
  if (
    !getGradePrice(props.pickers, '11') &&
    !getGradePrice(props.pickers, '10')
  ) {
    return {
      heading: i18n(unavailableTranslations.screenUsedHeader),
      title: i18n(unavailableTranslations.screenUsedTitle),
      image: getGradeImage(props.pickers, '12', 'screen'),
      status: getStepStatusWithPremium(StepIndexes.SCREEN),
    }
  }

  return false
})
const unavailableBodyProps = computed(() => {
  if (isPremiumStepAlone.value) {
    return false
  }

  // If "excellent" grade is not available, the body is always with scratches
  // If "good" grade is not available, the body has scratches if the screen has scratches
  if (
    !getGradePrice(props.pickers, '10') ||
    (!getGradePrice(props.pickers, '11') &&
      customSelection.value.grade === '12')
  ) {
    return {
      heading: i18n(unavailableTranslations.bodyUsedHeader),
      title: i18n(unavailableTranslations.bodyUsedTitle),
      image: getGradeImage(props.pickers, '11', 'body'),
      status: getStepStatusWithPremium(StepIndexes.SHELL),
    }
  }

  // If fair & good grades are not available, the body is always flawless
  // If "good" grade is not available, the body has flawless if the screen has flawless
  if (
    !getGradePrice(props.pickers, '11') &&
    (!getGradePrice(props.pickers, '12') ||
      customSelection.value.grade === '10')
  ) {
    return {
      heading: i18n(unavailableTranslations.bodyFlawlessHeader),
      title: i18n(unavailableTranslations.bodyFlawlessTitle),
      image: getGradeImage(props.pickers, '10', 'body'),
      status: getStepStatusWithPremium(StepIndexes.SHELL),
    }
  }

  return false
})

const hasCustomSelection = computed(() => {
  return (
    shouldDisplayPremiumDivider.value &&
    (stepStatuses.value[StepIndexes.SCREEN] !== 'unknown' ||
      stepStatuses.value[StepIndexes.SHELL] !== 'unknown' ||
      stepStatuses.value[StepIndexes.BATTERY] !== 'unknown')
  )
})

const hash = computed(
  () =>
    `#${stringify({
      ...parse(route.hash),
      scroll: 'false',
    })}`,
)

const premiumTracking = computed(() => {
  const screenSelection =
    stepStatuses.value[StepIndexes.SCREEN] !== 'unknown'
      ? stepStatuses.value[StepIndexes.SCREEN]
      : 'skipped'

  const bodySelection =
    stepStatuses.value[StepIndexes.SHELL] !== 'unknown'
      ? stepStatuses.value[StepIndexes.SHELL]
      : 'skipped'

  const batterySelection =
    stepStatuses.value[StepIndexes.BATTERY] !== 'unknown'
      ? stepStatuses.value[StepIndexes.BATTERY]
      : 'skipped'

  return {
    block_name: 'no_grade_premium',
    product_model: props.model,
    product_category: props.category,
    screen: isStepScreenAvailable.value ? screenSelection : 'out_of_stock',
    body: isStepBodyAvailable.value ? bodySelection : 'out_of_stock',
    battery: isStepBatteryAvailable.value ? batterySelection : 'out_of_stock',
  }
})

function resetPremiumStepStatus(): void {
  // Navigate to the previous selection
  const queryParams = {
    l: customSelectionWithoutPremium.value.grade,
    offerType:
      customSelectionWithoutPremium.value.offerType === 'default' ? '0' : '7',
  }
  const urlParams = {
    productId: customSelectionWithoutPremium.value.productId,
    slugV2: customSelectionWithoutPremium.value.slugV2,
  }
  router.push({
    name: PRODUCT.HOME,
    params: urlParams,
    hash: hash.value,
    query: {
      ...route.query,
      ...removeEmptyValuesInObject(queryParams),
    },
  })

  // Reset state
  setStatus(StepIndexes.PREMIUM, 'unknown')
}

function goToStep(index: number): void {
  // If we go back to a step before premium, we need to reset the selection to the previous one
  if (
    index < StepIndexes.PREMIUM &&
    stepStatuses.value[StepIndexes.PREMIUM] === 'accepted'
  ) {
    resetPremiumStepStatus()
  }

  // We don't have the battery step in the US, so we directly skip to premium
  if (index === StepIndexes.BATTERY && !shouldDisplayBatteryStep.value) {
    setActiveStep(index + 1)
  } else {
    setActiveStep(index)
  }
}
function goToPreviousStep() {
  goToStep(activeStep.value - 1)
}

function goToNextStep(): void {
  goToStep(activeStep.value + 1)
}
function resetNextStepsStatus(): void {
  for (let i = activeStep.value + 1; i < 4; i += 1) {
    setStatus(i, 'unknown')
  }
}

function accept(queryParams: Record<string, number>): void {
  setStatus(activeStep.value, 'accepted')
  resetNextStepsStatus()

  const urlParams = {
    uuid: customSelection.value.productId,
    slugV2: customSelection.value.slugV2,
  }

  router.push({
    name: PRODUCT.HOME,
    params: urlParams,
    hash: hash.value,
    query: {
      ...route.query,
      ...removeEmptyValuesInObject(queryParams),
    },
  })

  goToNextStep()
}
function decline(queryParams: Record<string, number>, goTo = 'next'): void {
  setStatus(activeStep.value, 'declined')
  resetNextStepsStatus()

  const urlParams = {
    uuid: customSelection.value.productId,
    slugV2: customSelection.value.slugV2,
  }

  router.push({
    name: PRODUCT.HOME,
    params: urlParams,
    hash: hash.value,
    query: {
      ...route.query,
      ...removeEmptyValuesInObject(queryParams),
    },
  })

  if (goTo === 'next') {
    goToNextStep()
  } else {
    goToPreviousStep()
  }
}

function getBackgroundForIndex(index: number): string {
  const isActiveStep = index === activeStep.value

  // When premium is selected we want to remove background from other steps
  const isPremiumStep = index === StepIndexes.PREMIUM
  const premiumIsSelectedButCurrentStepIsNotPremiumStep =
    !isPremiumStep && hasPremiumSelection.value
  // Premium exception: if we declined, we don't want the background to be pinky
  const isCurrentStepDeclined = stepStatuses.value[index] === 'declined'
  const premiumIsDeclined =
    isPremiumStep && isCurrentStepDeclined && !isPremiumStepAlone.value

  if (
    isActiveStep ||
    premiumIsSelectedButCurrentStepIsNotPremiumStep ||
    premiumIsDeclined
  ) {
    return BG_WITH_OPACITY
  }

  if (['accepted', 'declined'].includes(stepStatuses.value[index])) {
    return tw`!bg-surface-brand-hi`
  }
  if (index === StepIndexes.PREMIUM && !isPremiumStepAlone.value) {
    return tw`!bg-[#7B3DBE] mood-inverse`
  }

  if (activeStep.value >= StepIndexes.PREMIUM && !isPremiumStepAlone.value) {
    switch (index) {
      case StepIndexes.SCREEN:
        return tw`bg-float-default-hi`
      case StepIndexes.SHELL:
        return tw`bg-float-default-mid`
      case StepIndexes.BATTERY:
      default:
        return BG_WITH_OPACITY
    }
  }

  switch (Math.abs(activeStep.value - index)) {
    case 2:
      return tw`bg-float-default-hi`
    case 1:
    default:
      return tw`bg-float-default-mid`
  }
}

function getWidthForIndex(index: number): string {
  // Note: we used to return tailwind classes, but the last item is not interpreted as we cannot have dynamic classnames.
  if (activeStep.value === index) {
    return '100%'
  }

  if (isPremiumStepAlone.value) {
    return '100%'
  }

  // When premium is active (or done), its card is displayed separatly so we need to compute sizes differently
  if (activeStep.value >= StepIndexes.PREMIUM) {
    switch (index) {
      case StepIndexes.SCREEN:
        return '90%'
      case StepIndexes.SHELL:
        return '95%'
      case StepIndexes.BATTERY:
      case StepIndexes.PREMIUM:
      default:
        return '100%'
    }
  }

  // Distance algorithm: the active card is displayed at 100%, then we remove 5% per card, behing above or below.
  return `${100 - Math.abs(activeStep.value - index) * 5}%`
}

function getClassesForIndex(index: number): string {
  const sharedClasses = tw`ease-in-out motion-safe:transition-all motion-safe:duration-300 relative z-[1]`

  const prevStepsClasses = tw`rounded-b-none border-b-0 -mb-[8px]`
  const nextStepsClasses = tw`rounded-t-none border-t-0`
  // eslint-disable-next-line no-nested-ternary
  let notCurrentStepClasses = isPremiumStepAlone.value
    ? tw`rounded-lg`
    : index < activeStep.value
      ? prevStepsClasses
      : nextStepsClasses

  // Exception with the custom separator for premium
  if (
    index === StepIndexes.BATTERY &&
    activeStep.value >= StepIndexes.PREMIUM
  ) {
    notCurrentStepClasses = ''
  }
  if (
    index === StepIndexes.PREMIUM &&
    activeStep.value >= StepIndexes.PREMIUM
  ) {
    notCurrentStepClasses = ''
  }
  // Exception when we don't have new battery
  if (
    index === StepIndexes.SHELL &&
    !shouldDisplayBatteryStep.value &&
    activeStep.value >= StepIndexes.BATTERY
  ) {
    notCurrentStepClasses = ''
  }

  const background = getBackgroundForIndex(index)

  if (activeStep.value === index) {
    return tw`${background} ${sharedClasses}`
  }

  return tw`${background} ${notCurrentStepClasses} ${sharedClasses}`
}

function handleSelectionClick() {
  if (shouldDisplaySelection.value && activeStep.value > StepIndexes.PREMIUM) {
    decline(
      {
        l: parseInt(customSelectionWithoutPremium.value.grade, 10),
        offerType:
          customSelectionWithoutPremium.value.offerType === 'default' ? 0 : 7,
      },
      'previous',
    )
    stepStatuses.value[StepIndexes.PREMIUM] = 'unknown'
  }
}

/**
 * Provide the brand to the children components
 * deep in the component tree.
 *
 * This allows the children components to access the brand information
 * without having to pass it down through props at every level.
 */
provide(NO_GRADE_BRAND_INJECTION_KEY, props.brand)
</script>
