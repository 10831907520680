<template>
  <div class="mt-16">
    <div
      class="mb-8 flex flex-row"
      :class="{
        'justify-between': showEcoBlock,
        'justify-end': !showEcoBlock,
      }"
    >
      <EcoBlockAnchor v-if="showEcoBlock" />
      <FavoritesToggle
        class="bg-static-default-low rounded-full"
        :grade-id="selectedOffer?.grade.value"
        is-rounded
        :offer-type
        :price="selectedOffer?.price"
        :product-uuid="product.productId"
        :tracking-info="favoritesTrackingInfo"
        zone="details"
      />
    </div>

    <h1 class="heading-1 mb-8">
      {{ product.titles.raw }}
    </h1>

    <RatingInfo v-if="rating" anchor-id="reviews" class="mb-8" :rating />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'

import FavoritesToggle from '~/scopes/dashboard/favorites/toggle/FavoritesToggle.vue'
import { fromProductTrackingModel } from '~/scopes/dashboard/favorites/utils/FavoriteTrackingModel'
import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import { useProductTracking } from '../../composables/useProductTracking'
import { useUrlParams } from '../../composables/useUrlParams'

import EcoBlockAnchor from './components/EcoBlockAnchor/EcoBlockAnchor.vue'

const props = withDefaults(
  defineProps<{
    selectedOffer?: GetPickersResponse['selectedOffer'] | null
    product: GetProductResponse
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
  }>(),
  {
    selectedOffer: null,
    showEcoBlock: false,
    rating: null,
  },
)

const { offerType } = useUrlParams()

const favoritesTrackingInfo = computed(() => {
  return fromProductTrackingModel(props.tracking.product.value)
})
</script>
