<template>
  <div class="mb-16">
    <EcoBlockAnchor v-if="showEcoBlock" class="mb-8" />

    <h1 class="heading-1">
      {{ product.titles.raw }}
    </h1>

    <RatingInfo v-if="rating" anchor-id="reviews" :rating />

    <div class="mt-24 flex items-center gap-12">
      <Price
        v-if="product && selectedOffer"
        class="grow"
        :mobile-plan="selectedMobilePlan"
        :price
        :product
        :selected-offer
      />

      <RevButton
        class="min-w-[164px] max-w-[256px] grow"
        data-id="product-page-buy-button-desktop"
        data-qa="product-page-buy-button-desktop"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>

      <FavoritesToggle
        class="h-48"
        :grade-id="selectedOffer?.grade.value"
        :offer-type
        :price="selectedOffer?.price"
        :product-uuid="product.productId"
        :tracking-info="favoritesTrackingInfo"
        zone="details"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import FavoritesToggle from '~/scopes/dashboard/favorites/toggle/FavoritesToggle.vue'
import { fromProductTrackingModel } from '~/scopes/dashboard/favorites/utils/FavoriteTrackingModel'
import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import { useUrlParams } from '../../composables/useUrlParams'
import Price from '../Price/Price.vue'

import translations from './TitleDesktop.translations'
import EcoBlockAnchor from './components/EcoBlockAnchor/EcoBlockAnchor.vue'

const props = withDefaults(
  defineProps<{
    selectedOffer?: GetPickersResponse['selectedOffer']
    product: GetProductResponse
    price: PriceType
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
    selectedMobilePlan?: MobilePlanOffer
  }>(),
  {
    selectedOffer: null,
    selectedMobilePlan: undefined,
    showEcoBlock: false,
    rating: null,
  },
)

const { offerType } = useUrlParams()
const { addToCart } = useAddToCart()
const i18n = useI18n()

const favoritesTrackingInfo = computed(() => {
  return fromProductTrackingModel(props.tracking.product.value)
})

function onAddToCart() {
  if (props.selectedOffer && props.selectedOffer.offerLegacyId) {
    addToCart({
      listingId: props.selectedOffer.offerLegacyId,
      listingPublicId: props.selectedOffer.offerId,
      tracking: {
        ...props.tracking.product.value,
        list: 'product_page',
      },
      partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
      isTradeInEligible: props.product.isTradeInEligible,
    })
  }
}
</script>
