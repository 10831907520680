import type { Price } from '@backmarket/http-api'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

import { StepIndexes, type StepStatus } from './NoGrade.constants'

export const getGradeImage = (
  pickers: GetPickersResponse,
  g: string,
  type: 'body' | 'screen',
): string | undefined => {
  if (!pickers || !g) {
    return undefined
  }

  // API format is uppercase
  const formattedType = type.charAt(0).toUpperCase() + type.slice(1)

  return pickers.pickerGroups
    .find(({ id }) => id === 'grades')
    ?.sideContent?.content.find(({ grade }) => grade === parseInt(g, 10))
    ?.items.find(({ sectionId }) => sectionId === formattedType)?.imageUrl
}

export const getGradePrice = (
  pickers: GetPickersResponse,
  grade?: string | null | undefined,
  type: 'default' | 'new_battery' = 'default',
): Price | null | undefined => {
  const expectedOfferType = type === 'default' ? 0 : 7

  return pickers.gradeOffers[grade ?? '12']?.find(
    ({ offerType }) => offerType === expectedOfferType,
  )?.price
}

export const getDefaultGrade = (
  pickers: GetPickersResponse,
  startingGrade: number,
): string => {
  if (startingGrade < 9) {
    return ''
  }

  return pickers.gradeOffers[startingGrade]
    ? JSON.stringify(startingGrade)
    : getDefaultGrade(pickers, startingGrade - 1)
}

/**
 *
 * Fair = x screen + x body
 * Good = v screen + x body
 * Excellent = v screen + v body
 * Premium = overrides everything
 */
export const stepStatusesToGradeOfferType = (
  stepStatuses: Record<number, StepStatus>,
  activeStep: number,
  stepScreenUpdate: string,
  pickers: GetPickersResponse,
): { grade: string; offerType: 'default' | 'new_battery' } => {
  let grade = ''
  let offerType: 'default' | 'new_battery' = 'default'

  if (
    activeStep > StepIndexes.PREMIUM &&
    stepStatuses[StepIndexes.PREMIUM] === 'accepted'
  ) {
    return { grade: '9', offerType: 'default' }
  }
  if (
    activeStep > StepIndexes.BATTERY &&
    stepStatuses[StepIndexes.BATTERY] === 'accepted'
  ) {
    offerType = 'new_battery'
  } else {
    offerType = 'default'
  }
  if (
    activeStep > StepIndexes.SHELL &&
    stepStatuses[StepIndexes.SHELL] === 'accepted'
  ) {
    grade = '10'
  } else if (
    activeStep > StepIndexes.SCREEN &&
    stepStatuses[StepIndexes.SCREEN] === 'accepted' &&
    stepStatuses[StepIndexes.SHELL] !== 'accepted'
  ) {
    // Can be either 11 or 10
    grade = stepScreenUpdate
  } else {
    // Should be the first grade available starting from 12 to 9
    grade = getDefaultGrade(pickers, 12)
  }

  return { grade, offerType }
}

/*
 * Get default value of screen upgrade
 * Can be 10 or 11 as 12 is the screen downgrade
 */
export const getStepScreenUpdate = (pickers: GetPickersResponse) => {
  if (getGradePrice(pickers, '11')) {
    return '11'
  }

  if (getGradePrice(pickers, '10')) {
    return '10'
  }

  return '11'
}

export const getIsStepScreenAvailable = (pickers: GetPickersResponse) => {
  // If fair is not available, the screen is always flawless
  if (!getGradePrice(pickers, '12')) {
    return false
  }

  // If good and excellent grades are not available, the body is always with scratches
  if (!getGradePrice(pickers, '11') && !getGradePrice(pickers, '10')) {
    return false
  }

  return true
}

export const getIsStepBodyAvailable = (pickers: GetPickersResponse) => {
  // If "excellent" grade is not available, the body is always with scratches
  if (!getGradePrice(pickers, '10')) {
    return false
  }

  // If "good" grade is not available, we have nothing to compare, the body is either with scratches or flawless depending on the screen selection
  if (!getGradePrice(pickers, '11')) {
    return false
  }

  // If fair and good grades are not available, the body is always flawless
  if (!getGradePrice(pickers, '11') && !getGradePrice(pickers, '12')) {
    return false
  }

  return true
}

export const getIsStepBatteryAvailable = (
  pickers: GetPickersResponse,
  customSelection: ReturnType<typeof stepStatusesToGradeOfferType>,
) => {
  return !!getGradePrice(pickers, customSelection.grade, 'new_battery')
}
